@import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css';

.card-custom {
  background-color:#DAA520;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.card-title {
  font-size: 1.2rem; 
  font-weight: bold;
}

.card-custom li {
  font-size: 1.0rem; 
  margin-bottom: 0.5rem;
}

.card-custom:first-child {
  border-top-left-radius: 3rem; 
 
}
.card-custom:last-child {
  border-top-right-radius: 3rem; 
  border-bottom-right-radius: 3rem; 
}

.card-custom .card-body {
  padding: 1.5rem;
}

.card-custom .card-title {
  font-weight: bold;
}

.card-custom .card-text {
  color: black; 
  font-size: 0.9rem;
}

