.custom-footer {
    background-color:black; 
    color: white; 
  }

  .custom-footer a {
    color: white; 
    text-decoration: none; 
  }
  
  .custom-footer a:hover {
    color: #cccccc; 
    text-decoration: underline; 
  }
  
  