.card-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-spacing {
  margin-bottom: 20px;
}

.card-img {
  height: 200px;
  object-fit: cover;
}

.container {
  max-width: 1200px; 
}

button#custom-button.custom-button.btn.btn-primary:hover {
  background-color: black; 
  border-color: black;
}

.custom-button {
  background-color: black;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0; 
  padding: 12px 24px;
  font-size: 1.0rem;
  width: 100%;
  display: block;
  text-align: center;
  font-weight:bold;
  border-color: black;
  margin: 5px;
  
}

.custom-button:hover {
  background-color: black;
}

.small-text {
  font-size: 1.875rem;
  
}

.urbanride-heading {
  font-size: 28px;
  font-weight: bold;
}

.urbanride-name {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); 
}




