  .header {
    background-color: black; 
  }
  
  .navbar-brand .logo {
    height: 35px; 
  }
  
  .navbar-nav {
    align-items: center;
  }
  
  .navbar-collapse {
    justify-content: flex-end;
  }

  .navbar-brand img {
    height: 40px; 
  }


  
  