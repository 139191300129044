.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); 
  color: #333; 
}

h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.img-fluid.rounded {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.img-fluid.rounded:hover {
  transform: scale(1.05);
}

/* .carousel-img {
height: 1200px; 
object-fit: contain; 
} */




